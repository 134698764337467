import { httpService } from 'core-utilities';
import urlConstant from '../constants/urlConstants';

const { getAgeRecommendationUrl } = urlConstant;

export default {
  getAgeRecommendation: universeId => {
    const urlConfig = {
      url: getAgeRecommendationUrl(),
      retryable: true,
      withCredentials: true
    };

    const data = {
      universeId
    };

    return httpService.post(urlConfig, data);
  }
};
