import { ready } from 'core-utilities';
import React from 'react';
import { render } from 'react-dom';
import AgeRecommendationTitle from './containers/AgeRecommendationTitle';
import '../../../css/gameGuidelines/gameGuidelines.scss';
import LicensedBadgeContainer from './components/LicensedBadgeContainer';

const ageRecommendationTitleContainer = 'game-age-recommendation-container';
const ageRecommendationDetailContainer = 'game-age-recommendation-details';

const insertLicensedBadge = (element: HTMLElement) => {
  const licensedBadgeContainer = document.createElement('div');
  licensedBadgeContainer.id = 'game-licensed-badge-container';
  element.parentElement?.insertBefore(licensedBadgeContainer, element.nextSibling);

  render(<LicensedBadgeContainer />, licensedBadgeContainer);
};

ready(() => {
  let ageRecommendationDetailContainerElement = document.getElementById(
    ageRecommendationDetailContainer
  );
  if (ageRecommendationDetailContainerElement) {
    render(
      <AgeRecommendationTitle isDisplayAgeRecommendationDetails />,
      ageRecommendationDetailContainerElement
    );
    ageRecommendationDetailContainerElement = null;
  }
  let ageRecommendationTitleContainerElement = document.getElementById(
    ageRecommendationTitleContainer
  );
  if (ageRecommendationTitleContainerElement) {
    render(
      <AgeRecommendationTitle isDisplayAgeRecommendationDetails={false} />,
      ageRecommendationTitleContainerElement
    );
    insertLicensedBadge(ageRecommendationTitleContainerElement);
    ageRecommendationTitleContainerElement = null;
  }
});
