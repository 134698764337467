import { EnvironmentUrls } from 'Roblox';
import { urlService } from 'core-utilities';

const { apiGatewayUrl } = EnvironmentUrls;

export default {
  getAgeRecommendationUrl: (): string =>
    `${apiGatewayUrl}/experience-guidelines-api/experience-guidelines/get-age-recommendation`,
  getAssetTextFilterSettingsUrl: (universeId: string): string =>
    `${apiGatewayUrl}/asset-text-filter-settings/public/universe/${universeId}`,
  experienceGuidelinesPolicyPageUrl: (locale: string): string =>
    urlService.getUrlWithLocale('/info/age-recommendations-policy', locale)
};
