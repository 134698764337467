import { useState, useEffect } from 'react';
import bedev1Services from '../../common/services/bedev1Services';
import { TGetGameDetails } from '../../common/types/bedev1Types';

const useGameDetailsForUniverseId = (universeId: string): TGetGameDetails | undefined => {
  const [gameDetails, setGameDetails] = useState<TGetGameDetails | undefined>(undefined);

  useEffect(() => {
    bedev1Services
      .getGameDetails(universeId)
      .then(response => setGameDetails(response))
      .catch(() => {
        // Non-blocking, as the non-dependent sections will still be displayed
      });
  }, [universeId]);
  return gameDetails;
};

export default useGameDetailsForUniverseId;
