import { httpService } from 'core-utilities';
import urlConstant from '../constants/urlConstants';
import { AssetTextFilterSettingsResponse } from '../types';

const { getAssetTextFilterSettingsUrl } = urlConstant;

const getAssetTextFilterSettings = async (
  universeId: string
): Promise<AssetTextFilterSettingsResponse> => {
  const atfsUrl = {
    url: getAssetTextFilterSettingsUrl(universeId),
    retryable: true,
    withCredentials: true
  };

  const { data }: { data: AssetTextFilterSettingsResponse } = await httpService.get(atfsUrl);
  return data;
};

export default { getAssetTextFilterSettings };
